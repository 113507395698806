import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ddw-admin-user-home',
  templateUrl: './admin-user-home.component.html',
  styleUrls: ['./admin-user-home.component.css']
})
export class AdminUserHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
