import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ddw-admin-press-release',
  templateUrl: './admin-press-release.component.html',
  styleUrls: ['./admin-press-release.component.css']
})
export class AdminPressReleaseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
