import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ddw-admin-custom-nav-link-list',
  templateUrl: './admin-custom-nav-link-list.component.html',
  styleUrls: ['./admin-custom-nav-link-list.component.css']
})
export class AdminCustomNavLinkListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
