import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ddw-call-to-action-item',
  templateUrl: './call-to-action-item.component.html',
  styleUrls: ['./call-to-action-item.component.css']
})
export class CallToActionItemComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
