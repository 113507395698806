// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    firebase: {
        apiKey: 'AIzaSyBTHLRs1aFm8O0v-60LCUUg0xqPtHa5lzk',
        authDomain: 'ddw-org-dev.firebaseapp.com',
        databaseURL: 'https://ddw-org-dev.firebaseio.com',
        projectId: 'ddw-org-dev',
        storageBucket: 'ddw-org-dev.appspot.com',
        messagingSenderId: '663508429049'
    },
    mceApiKey: 'qpgcivhhbort3bf4mz1nvpgin5wwkvumzsw6nczmoxuhjz1k',
};
