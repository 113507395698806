import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ddw-admin-image-uploader',
    templateUrl: './admin-image-uploader.component.html',
    styleUrls: ['./admin-image-uploader.component.css']
})
export class AdminImageUploaderComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }


}
