import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ddw-admin-file-uploader',
  templateUrl: './admin-file-uploader.component.html',
  styleUrls: ['./admin-file-uploader.component.css']
})
export class AdminFileUploaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
