import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ddw-press-releases',
    templateUrl: './press-releases.component.html',
    styleUrls: ['./press-releases.component.css']
})
export class PressReleasesComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
        /*------------------------------------------------
         Press release list is controlled from News & Media Component
         ------------------------------------------------*/
    }

}

