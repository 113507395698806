import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ddw-admin-custom-nav-link',
  templateUrl: './admin-custom-nav-link.component.html',
  styleUrls: ['./admin-custom-nav-link.component.css']
})
export class AdminCustomNavLinkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
